import './App.css';
import logo from './assets/images/logo.svg'
import logo2 from './assets/images/white-logo.svg'
import heroImg from './assets/images/hero-img.svg'
import service1 from './assets/images/service-1.png'
import service2 from './assets/images/service-2.png'
import { Grid, Stack } from '@mui/material';
import { HashLink as Link } from 'react-router-hash-link';

import { HashRouter as Router} from "react-router-dom";

function App() {
  return (
    <div className="App">
      <Router>
      {/* navbar */}
      <Stack spacing={4} className='navbar container'>
        <div>
        <img src={logo} alt='logo' />
        </div>
        <hr />
        <ul>
          <li> <Link smooth to="/#about">ABOUT</Link> </li>
          <li> <Link smooth to="/#services">SERVICES</Link> </li>
          <li> <Link smooth to="/#contact">CONTACT</Link> </li>
        </ul>
      </Stack>
      {/* ================= */}
      {/* hero sec */}
      <div className='hero-sec'>
        <img src={heroImg} alt='containers image' />
      </div>
      {/* ================ */}
      
      {/* About sec */}
      <Stack id="about" spacing={4} className='about-sec container'>
        <h2>ABOUT</h2>
        <p>Bellavi International BV is an international consulting firm specializing in trade compliance and legal compliance for imports and export for products to and from the European Union.</p>
        <p>The firm is based in Amsterdam, The Netherlands. We have several years of industry experience within the field as well as a Certified Export Compliance Manager (CECM) to ensure maximum compliance for our customers.</p>
      </Stack>
      {/* ================== */}

      <div className='container'>
        <hr />

      </div>

      {/* services sec */}
      <div id="services" className='services-sec container'>
        <h2>SERVICES</h2>
        <Grid justifyContent='space-between' container>
          <Grid mt={10} xs={12} lg={5} item className='service-item'>
            <Stack spacing={3}>
              <div>
              <img src={service2} alt='' />
              </div>
              <h3>Dual-Use Screening</h3>
              <p>The requirements for Dual-Use screening is increasing in many countries all over the world, primarily due to increased sanctions from the EU and USA. A dual-use screening ensures compliance with these regulations to avoid heavy fines.</p>
            </Stack>
          </Grid>
          <Grid mt={10} xs={12} lg={5} item className='service-item'>
            <Stack spacing={3}>
            <div>
            <img src={service1} alt='' />
            </div>
            <h3>Import / Export</h3>
            <p>If you want to introduce a new product to the EU market, getting the right certification and testing for your product could be a costly and time consuming process. We streamline the process and tailor make it for your needs and your product. Due to our vast network and experience, we can facilitate this process for you, making it easier and more time efficient. </p>
            </Stack>
          </Grid>
        </Grid>
      </div>
      {/* ====================== */}

      {/* contact sec */}
      <div id="contact" className='contact-sec'>
          <Stack className='container' spacing={5}>
            <h2>CONTACT</h2>
            <Stack spacing={1}>
              <h4>Bellavi International</h4>
              <p>martin@bellaviinternational.com</p>
              <p>+46769275320</p>
            </Stack>
            <div>
              <img src={logo2} alt="logo" />
            </div>
          </Stack>
      </div>
      </Router>

    </div>
  );
}

export default App;
